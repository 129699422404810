import ReactGA from "react-ga4";

const useAnalyticsEventTracker = (category="Click", action, label, value) => {
    const eventTracker = (varaction = action, varlabel = label, varvalue = value) => {
        if (process.env.NODE_ENV === "production"){ 
            ReactGA.event({category: category,action: varaction,label:varlabel, value:varvalue}); 
        }
    }
    return eventTracker;
  }

export default useAnalyticsEventTracker;
