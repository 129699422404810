import React, {useState} from 'react'; 
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import './ScrollToTopButton.css';
import { last } from 'lodash';

function ScrollToTopButton() {
  const [visible, setVisible] = useState(false) 

  var scrollBefore = 0;
  var lastPosition =  window.scrollY;
  const scrolled = window.scrollY;


  const toggleVisible = () => { 
    const scrolled = document.documentElement.scrollTop; 
    if (scrolled >125){ 
      setVisible(true);
      
      if(lastPosition >= window.scrollY)
      {
        if(!sessionStorage.getItem('scrollDirectionUp'))
        {
          sessionStorage.setItem('scrollDirectionUp', true);
        }
      }
      else if(sessionStorage.getItem('scrollDirectionUp') && lastPosition > 125)
      {
        sessionStorage.removeItem('scrollDirectionUp')
      }
      lastPosition = window.scrollY; 
        
    }  
    else if (scrolled <=125){ 
      setVisible(false); 
    } 


  }; 

  const scrollToTop = () =>{ 
    window.scrollTo({ 
      top: 0,  
      behavior: 'smooth'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    }); 
  }; 
  

  window.addEventListener('scroll', toggleVisible);
  window.addEventListener('resize', toggleVisible);
  window.addEventListener('scroll',updatePositionOfScrollToTop);


  function updatePositionOfScrollToTop(e){
    if(scrollBefore < scrolled){
      if(visible)
        document.getElementById('scroll-to-top').style.bottom = "15px";
      window.removeEventListener('scroll',updatePositionOfScrollToTop);
    }
  }


  return ( 
    <IconButton onClick={scrollToTop}> 
     <KeyboardArrowUpIcon  
     className='scroll-to-top'
     id="scroll-to-top"
    style={{ bottom: visible ? '15px' : '-50px'}}/> 
    </IconButton> 
  ); 
}

export default ScrollToTopButton;