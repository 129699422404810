import { useTranslation } from "react-i18next";
import { supportedLngs } from "./config";
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

export default function LocaleSwitcher() {
  const { i18n } = useTranslation();

  return (
    <FormControl fullWidth>
        <Select
          style={{ width: '100%'}}
          value={i18n.resolvedLanguage}
          onChange={(e) => i18n.changeLanguage(e.target.value)}
        >
          {Object.entries(supportedLngs).map(([code, name]) => (
                <MenuItem value={code} key={code}>{name}</MenuItem>
          ))}
        </Select>
        </FormControl>
  );
}