export default  {
    name: 'customDetector',
  
    lookup(options) {      
      if (localStorage.getItem('language'))
      {
        return localStorage.getItem('language');
      }
      else 
        return '';
    }
  };