import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

const handleClose = () => {
    window.history.back();
};


export default function MobileInstruction(props) {
    return (
        <Dialog
            className='DialogBox'
            sx={{ m: 0, p: 0 }}
            open={props.open}
            onClose={handleClose}
            TransitionComponent={props.Transition}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} >
                {props.title}
            </DialogTitle>
            <IconButton
                aria-label="fermer"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 4,
                    top: 4,
                    color: '#000',
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ p: 0 }} className='DialogBoxContent'>

                <DialogContentText>


                    <div>
                        {props.intro &&
                            <p>{props.intro}</p>
                        }

                        {props.step1Description &&
                            <React.Fragment>
                                <h2 className='titleColor1'><span className='bandeDore2'>{props.step1Title}</span></h2>
                                <p>
                                    {props.step1Description}<br />
                                    <img alt={props.step1Title} src={props.folder + 'Step 1.png'} ></img>
                                </p>     </React.Fragment>}
                        {props.step2Description &&
                            <React.Fragment>
                                <h2 className='titleColor1'><span className='bandeDore2'>{props.step2Title}</span></h2>
                                <p>
                                    {props.step2Description}<br />
                                    <img alt={props.step2Title} src={props.folder + 'Step 2.png'} ></img>
                                </p>
                            </React.Fragment>
                        }
                        {props.step3Description &&
                            <React.Fragment>
                                <h2 className='titleColor1'><span className='bandeDore2'>{props.step3Title}</span></h2>
                                <p>
                                    {props.step3Description}<br />
                                    <img alt={props.step3Title} src={props.folder + 'Step 3.png'} ></img>
                                </p>
                            </React.Fragment>
                        }
                        {props.step4Description &&
                            <React.Fragment>
                                <h2 className='titleColor1'><span className='bandeDore2'>{props.step4Title}</span></h2>
                                <p>
                                    {props.step4Description}<br />
                                    <img alt={props.step4Title} src={props.folder + 'Step 4.png'} ></img>
                                </p>
                            </React.Fragment>
                        }
                        {props.step5Description &&
                            <React.Fragment>
                                <h2 className='titleColor1'><span className='bandeDore2'>{props.step5Title}</span></h2>
                                <p>
                                    {props.step5Description}<br />
                                    <img alt={props.step5Title} src={props.folder + 'Step 5.png'} ></img>
                                </p>
                            </React.Fragment>
                        }
                        {props.conclusion &&
                            <p>{props.conclusion}</p>
                        }
                    </div>
                    <br></br>
                    <Button className='button-color1' onClick={handleClose}>{props.button}</Button>
                </DialogContentText>
            </DialogContent>
        </Dialog>

    );
}