// Core i18next library.
import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import customDetector from "./customDetector";
const languageDetector = new LanguageDetector();
languageDetector.addDetector(customDetector);

// Add names for each locale to
// show the user in our locale
// switcher.
export const supportedLngs = {
  fr: "Français",
  en: "English",
};

i18n
  // Add React bindings as a plugin.
  // Wire up the backend as a plugin.
  .use(HttpApi)
  .use(languageDetector)
  .use(initReactI18next)
  // Initialize the i18next instance.
  .init({
    // Config options
    // Fallback locale used when a translation is
    // missing in the active locale. Again, use your
    // preferred locale here. 
    backend: {
      loadPath: process.env.REACT_APP_API_URL + '/Translation?lang={lng}',
      crossDomain: true
    },
   // fallbackLng: "en",
    // Explicitly tell i18next our
    // supported locales.
    supportedLngs: Object.keys(supportedLngs),

    // Enables useful output in the browser’s
    // dev console.
    debug: process.env.NODE_ENV === "production" ? false : true,

    // Normally, we want `escapeValue: true` as it
    // ensures that i18next escapes any code in
    // translation messages, safeguarding against
    // XSS (cross-site scripting) attacks. However,
    // React does this escaping itself, so we turn 
    // it off in i18next.
    interpolation: {
      escapeValue: false,
      prefix: "{",
      suffix: "}"
    }
  });

export default i18n;


