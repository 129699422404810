import axios from 'axios';
import queryString from "query-string";


export const sendData = (message, type) =>
{
  var url = queryString.stringifyUrl({
    url: process.env.REACT_APP_ANALYTICAPI_URL + "/log",
    query: {
      message,
      type,
      userId:localStorage.getItem('userId')
    }
  });

  axios.get(url).catch(function (error) {
   
  });
}

export const formatDecimal = (num) =>
{
  if(Number.isInteger(num))
    return num;
  else
    return (Math.round(num * 100) / 100).toFixed(2);
}