import * as React from 'react';
import './Header.css';
import './Share.css';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import useAnalyticsEventTracker from './useAnalyticsEventTracker';
import MobileInstruction from './MobileInstruction';
import LocaleSwitcher from '../i18n/LocaleSwitcher';
//import useWindowDimensions from './hooks/useWindowDimensions';


//Dialog
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

//drawer
import Drawer from "@mui/material/Drawer";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { isAndroid, isIOS, isChrome, isEdge, isSafari, isFirefox, isSamsungBrowser, browserName, osName } from 'react-device-detect';
import { t } from 'i18next';


const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SearchAppBar() {

  const [open, setOpen] = React.useState(window.location.hash === "#openInfo");
  const [openMenu, setOpenMenu] = React.useState(false);
  const [pageMenu, setPageMenu] = React.useState('info');
  const gaEventTracker = useAnalyticsEventTracker('menu');
  const isFacebookInAppBrowser = navigator.userAgent.match(/FBAN|FBAV|FB_IAB/i);

  React.useEffect(() => {
    const onHashChange = () => setOpen(window.location.hash === "#openInfo");
    window.addEventListener("hashchange", onHashChange);
    return () => window.removeEventListener("hashchange", onHashChange);
  }, []);

  const handleClickOpen = (pageMenu) => {
    setPageMenu(pageMenu);
    gaEventTracker('click', pageMenu)
    window.location.hash = "#openInfo";

    return false;
  };

  const handleClose = () => {
    window.history.back();
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    //changes the function state according to the value of open
    setOpenMenu(open);
  };

  return (
    <Box sx={{ flexGrow: 1, minHeight: "30px" }}>
      <AppBar position="fixed" className="topBar">
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'flex' },
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }} >
            <img alt='DealsEmpire Logo' className='logo' src="/DealsEmpire_LOGO_Rectangles_01_clear.png" />
          </Typography>
          {openMenu === false &&
            <IconButton aria-label="menu" className="appInfoButton" onClick={toggleDrawer(true)}>
              <MenuIcon fontSize="large" />
            </IconButton>
          }
          {openMenu === true &&
            <IconButton aria-label="menu" className="appInfoButton" onClick={toggleDrawer(false)}>
              <CloseIcon fontSize="large" />
            </IconButton>
          }
        </Toolbar>
      </AppBar>
      <Drawer
        className="mobile-menu"
        //from which side the drawer slides in
        anchor="right"
        //if open is true --> drawer is shown
        open={openMenu}
        //function that is called when the drawer should close
        onClose={toggleDrawer(false)}
        //function that is called when the drawer should open
        onOpen={toggleDrawer(true)}
      >
        {/* The inside of the drawer */}
        <Box className="mobile-menu-inside" sx={{
          p: 2,
          height: 1
        }}>
          <Box sx={{ mb: 2 }}>
            <ListItemButton>
              <ListItemText primary={t("Menu_Button_Install")} onClick={(e) => { e.preventDefault(); handleClickOpen('install'); return false; }} />
            </ListItemButton>

            <ListItemButton>
              <ListItemText primary={t("Menu_Button_About")} onClick={(e) => { e.preventDefault(); handleClickOpen('info'); return false; }} />
            </ListItemButton>
            
            <ListItemButton className='language'>              
                <LocaleSwitcher></LocaleSwitcher>             
            </ListItemButton>
          </Box>

        </Box>

      </Drawer>
      <Offset className='topBarOffset' />
      {pageMenu === "install" &&
        <div>
   {(isChrome && isAndroid) &&
          <MobileInstruction folder="/Tuto/AndroidChrome/"
            open={open}
            Transition={Transition}
            title={t("Tutorial_Android_Chrome_Title")}
            step1Title={t("Tutorial_Android_Chrome_Step_1_Title")}
            step1Description={t("Tutorial_Android_Chrome_Step_1_Description")}
            step2Title={t("Tutorial_Android_Chrome_Step_2_Title")}
            step2Description={t("Tutorial_Android_Chrome_Step_2_Description")}
            step3Title={t("Tutorial_Android_Chrome_Step_3_Title")}
            step3Description={t("Tutorial_Android_Chrome_Step_3_Description")}
            step4Title={t("Tutorial_Android_Chrome_Step_4_Title")}
            step4Description={t("Tutorial_Android_Chrome_Step_4_Description")}
            conclusion={t("Tutorial_Android_Chrome_Conclusion")}
            button={t("Tutorial_Android_Chrome_Button")}
          >
          </MobileInstruction>
}
          {isEdge && isAndroid &&
            <MobileInstruction folder="/Tuto/AndroidEdge/"
              open={open}
              Transition={Transition}
              title={t("Tutorial_Android_Edge_Title")}
              step1Title={t("Tutorial_Android_Edge_Step_1_Title")}
              step1Description={t("Tutorial_Android_Edge_Step_1_Description")}
              step2Title={t("Tutorial_Android_Edge_Step_2_Title")}
              step2Description={t("Tutorial_Android_Edge_Step_2_Description")}
              step3Title={t("Tutorial_Android_Edge_Step_3_Title")}
              step3Description={t("Tutorial_Android_Edge_Step_3_Description")}
              step4Title={t("Tutorial_Android_Edge_Step_4_Title")}
              step4Description={t("Tutorial_Android_Edge_Step_4_Description")}
              step5Title={t("Tutorial_Android_Edge_Step_5_Title")}
              step5Description={t("Tutorial_Android_Edge_Step_5_Description")}
              conclusion={t("Tutorial_Android_Edge_Conclusion")}
              button={t("Tutorial_Android_Edge_Button")} >
            </MobileInstruction>}



          {isFirefox && isAndroid &&
            <MobileInstruction folder="/Tuto/AndroidFirefox/"
              open={open}
              Transition={Transition}
              title={t("Tutorial_Android_Firefox_Title")}
              step1Title={t("Tutorial_Android_Firefox_Step_1_Title")}
              step1Description={t("Tutorial_Android_Firefox_Step_1_Description")}
              step2Title={t("Tutorial_Android_Firefox_Step_2_Title")}
              step2Description={t("Tutorial_Android_Firefox_Step_2_Description")}
              step3Title={t("Tutorial_Android_Firefox_Step_3_Title")}
              step3Description={t("Tutorial_Android_Firefox_Step_3_Description")}
              conclusion={t("Tutorial_Android_Firefox_Conclusion")}
              button={t("Tutorial_Android_Firefox_Button")}  >
            </MobileInstruction>
          }


          {isSamsungBrowser && isAndroid &&
            <MobileInstruction folder="/Tuto/AndroidSamsung/"
              open={open}
              Transition={Transition}
              title={t("Tutorial_Android_Samsung_Title")}
              step1Title={t("Tutorial_Android_Samsung_Step_1_Title")}
              step1Description={t("Tutorial_Android_Samsung_Step_1_Description")}
              step2Title={t("Tutorial_Android_Samsung_Step_2_Title")}
              step2Description={t("Tutorial_Android_Samsung_Step_2_Description")}
              step3Title={t("Tutorial_Android_Samsung_Step_3_Title")}
              step3Description={t("Tutorial_Android_Samsung_Step_3_Description")}
              step4Title={t("Tutorial_Android_Samsung_Step_4_Title")}
              step4Description={t("Tutorial_Android_Samsung_Step_4_Description")}
              conclusion={t("Tutorial_Android_Samsung_Conclusion")}
              button={t("Tutorial_Android_Samsung_Button")}
            >
            </MobileInstruction>
          }

          {isIOS && isSafari &&
            <MobileInstruction folder="/Tuto/iOSSafari/"
              open={open}
              Transition={Transition}
              title={t("Tutorial_IOS_Safari_Title")}
              step1Title={t("Tutorial_IOS_Safari_Step_1_Title")}
              step1Description={t("Tutorial_IOS_Safari_Step_1_Description")}
              step2Title={t("Tutorial_IOS_Safari_Step_2_Title")}
              step2Description={t("Tutorial_IOS_Safari_Step_2_Description")}
              step3Title={t("Tutorial_IOS_Safari_Step_3_Title")}
              step3Description={t("Tutorial_IOS_Safari_Step_3_Description")}
              step4Title={t("Tutorial_IOS_Safari_Step_4_Title")}
              step4Description={t("Tutorial_IOS_Safari_Step_4_Description")}
              conclusion={t("Tutorial_IOS_Safari_Conclusion")}
              button={t("Tutorial_IOS_Safari_Button")}
            >
            </MobileInstruction>
          }

          {isFacebookInAppBrowser &&
            <MobileInstruction folder="/Tuto/META/"
              open={open}
              Transition={Transition}
              title={t("Tutorial_META_Facebook_Title")}
              intro={t("Tutorial_META_Facebook_Intro")}
              step1Title={t("Tutorial_META_Facebook_Step_1_Title")}
              step1Description={t("Tutorial_META_Facebook_Step_1_Description")}
              step2Title={t("Tutorial_META_Facebook_Step_2_Title")}
              step2Description={t("Tutorial_META_Facebook_Step_2_Description")}
              conclusion={t("Tutorial_META_Facebook_Conclusion")}
              button={t("Tutorial_META_Facebook_Button")}
            >
            </MobileInstruction>
          }
          {!(((isChrome || isEdge || isFirefox || isSamsungBrowser) && isAndroid) || (isIOS && isSafari) || (isFacebookInAppBrowser)) &&

            <MobileInstruction
              open={open}
              Transition={Transition}
              title={t("Tutorial_Unknown_Title")}
              intro={t("Tutorial_Unknown_Content")}
              conclusion={<a target="_blank" rel="noreferrer" href={["https://www.google.com/search?q=Comment+installer+une+PWA+sur+" + osName + "+" + browserName]}>{t("Tutorial_Unknown_More_Info")}</a>}
              button={t("Tutorial_Unknown_Button")} >
            </MobileInstruction>
          }
        </div>
      }
      {
        pageMenu === "info" &&
        <Dialog
          className='DialogBox'
          sx={{ m: 0, p: 0 }}
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} >
            {t("About_Title")}
          </DialogTitle>
          <IconButton
            aria-label="fermer"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 4,
              top: 4,
              color: '#000',
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ p: 0 }} >

            <DialogContentText>
              <div>{t("About_Content")}</div>
              <br></br>
              <Button className='button-color1' onClick={handleClose}>{t("About_Button")}</Button>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      }

    </Box>
  );
}
