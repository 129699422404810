import './App.css';
import DealList from './components/DealList.js';
import Header from './components/Header'
import ScrollToTopButton from './components/ScrollToTopButton.js'
import * as React from 'react';
import axios from 'axios';
import queryString from "query-string";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from "@mui/material/ListItemIcon";
import Slide from "@mui/material/Slide";
import Button from '@mui/material/Button';
import ReactGA from 'react-ga4';
import useAnalyticsEventTracker from './components/useAnalyticsEventTracker';
import { RotatingLines } from 'react-loader-spinner'
import { debounce } from 'lodash';
import { useAddToHomescreenPrompt } from "./functions/AddToHomeScreen.js";
import { isAndroid, isIOS, isChrome, isSafari, isFirefox,isMobile} from 'react-device-detect';
import { useTranslation } from "react-i18next";
import { sendData } from './functions/Utils.js'   


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const RefreshTimerTime = 45;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      width: 250
    },
  },
  style: {
    position: 'absolute',
  },
  disableScrollLock: true
};

function App() {

  const { t, i18n } = useTranslation();
  const [deals, setDeals] = React.useState([]);
  const [tempDeals, setTempDeals] = React.useState([]);

  //
  const [search, setSearch] = React.useState((sessionStorage.getItem('dealId') && sessionStorage.getItem('search')) ? sessionStorage.getItem('search') : getHashSearch());
  const [sort, setSort] = React.useState((sessionStorage.getItem('dealId') && sessionStorage.getItem('sort')) ? sessionStorage.getItem('sort') : 3);
  const [selected, setSelected] = React.useState((sessionStorage.getItem('dealId') && sessionStorage.getItem('selected')) ? JSON.parse(sessionStorage.getItem('selected')) : []);


  const [openSort, setOpenSort] = React.useState(false);
  const [openCategorie, setOpenCategorie] = React.useState(false);

  const [hasMore, setHasMore] = React.useState(false);
  const [noResult, setNoResult] = React.useState(false);
  const [categories, setCategories] = React.useState([]);

  const [initialLoadShow, setInitialLoadShow] = React.useState(false);
  const [inListLoadShow, setInListLoadShow] = React.useState(!sessionStorage.getItem('dealId'));

  const POSTS_ENDPOINT = process.env.REACT_APP_API_URL + "/Deals";

  const isAllSelected = categories.length > 0 && selected.length === categories.length;
  const gaEventTracker = useAnalyticsEventTracker('Header');
  const isFacebookInAppBrowser = navigator.userAgent.match(/FBAN|FBAV|FB_IAB/i);

  const isRefScroller = React.useRef(null);

  //install to home screen
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isAppInstalled, setIsAppInstalled] = React.useState(false);
  const [isVisible, setVisibleState] = React.useState(false);

  const [timer, setTimer] = React.useState(true);
  const [timerId, setTimerId] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false); 
  

  React.useEffect(() => {
    isPWAInstalled();
    getLanguage();
    document.addEventListener("touchmove", function (e) {
      document.getElementById("bar-search-field").blur();
    });
  }, []);

  const refreshPage = () =>  {
    if (window.scrollY <= 0) {
      setNoResult(false);
      setDeals([]);
      isRefScroller.current.pageLoaded = 0;
      setHasMore(true);     
      setTimer(false);
    }
  }

  React.useEffect( () => {
    if (timer) {
  
       // timer should start
       if (window.scrollY <= 0) {
        let id = setTimeout( () => { refreshPage();} , (RefreshTimerTime * 1000));
        setTimerId(id);
      }
    } else if (!timer && timerId) {
       // timer is started but is interrupted by user
       clearTimeout(timerId);
       setTimer(true);
    }
    return () => {
       // clean up
       if (timerId) {
           clearTimeout(timerId);
       }
    }
  }, [timer]); // listen to changes on timer state

  const isPWAInstalled = async () => {
    if ("getInstalledRelatedApps" in window.navigator) {
      const relatedApps = await navigator.getInstalledRelatedApps();
      let installed = false;
      relatedApps.forEach((app) => {
        //if your PWA exists in the array it is installed
        console.log(app.platform, app.url);
        if (app.url === (process.env.REACT_APP_MAIN_URL + "/" + process.env.REACT_APP_MANIFEST_NAME)) {
          installed = true;
        }
      });
      setIsAppInstalled(installed);

      if(!installed)
      {
        if (!sessionStorage.getItem('prompt')){
          setVisibleState(true);
          sessionStorage.setItem('prompt', true);
        }
      }

      if (!sessionStorage.alreadyLogged) {
        sendData(installed ,'IsInstalled');
        sessionStorage.alreadyLogged = 1;
      }      
    }
  };

  if (process.env.NODE_ENV === "production") {
    const TRACKING_ID = "G-C1893R248H";
    ReactGA.initialize(TRACKING_ID);
  }

  function getLanguage()
  {

    if(isMobile)
    {
      if (localStorage.getItem('language'))
      {
        return;
      }
      
      var url = queryString.stringifyUrl({
        url: process.env.REACT_APP_API_URL + "/Language"
      });

      axios.get(url, {
        validateStatus: function (status) {
          return status < 500; // Resolve only if the status code is less than 500
        }
      })
      .then(res => {
        if (res.status < 400) {
          localStorage.setItem('language', res.data);
          
          if(i18n.resolvedLanguage !== res.data)
          {
            i18n.changeLanguage(res.data);
          }
        }
        else {
          return '';
        }
      })
    }
  }

  function getDeals(page) {
    setIsLoading(true);
  
    var find = ',';
    var re = new RegExp(find, 'g');
    var str = selected.toString().replace(re, '|');

    var url = queryString.stringifyUrl({
      url: POSTS_ENDPOINT,
      query: {
        page,
        limit: 20,
        search: search,
        categories: str,
        sortType: sort
      }
    });

    axios.get(url, {
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      }
    })
      .then(res => {
        if (res.status < 400) {

          setNoResult(false);
          gaEventTracker('pageLoad', '', page);


          if (sessionStorage.getItem('page')) {
            if (sessionStorage.getItem('page') > page && sessionStorage.getItem('dealId')) {
              if (page === 1)
                setInitialLoadShow(true);

              setTempDeals(tempDeals => [...tempDeals, ...res.data]);
            }
            else {
              setInListLoadShow(true);
              if (isFacebookInAppBrowser)
                sessionStorage.setItem('page', page);
              if (sessionStorage.getItem('dealId')) {
                setDeals([...tempDeals, ...res.data]);
                setTempDeals([]);
              }
              else {
                setDeals(deals => [...deals, ...res.data]);
                setInitialLoadShow(false);

              }
            }
          }
          else {
            setDeals(deals => [...deals, ...res.data]);
            if (isFacebookInAppBrowser)
              sessionStorage.setItem('page', page);
          }
          setIsLoading(false);
        }
        else {
          setNoResult(true);
          setHasMore(false);
          gaEventTracker('pageLoadEnd', '', page);
          setIsLoading(false);
        }
      })


  }


  function getHashSearch() {
    if (window.location.hash == 0)
      return '';

    let splitted = window.location.hash.substring(1).split('|');
    if (splitted.length === 0)
      return '';
    let searchhash = splitted.filter(x => x.startsWith('search='))[0];

    if (searchhash.length > 7) {
      window.location.hash = '';
      let searchValue = searchhash.substring(7).replaceAll('+', ' ');
      return decodeURIComponent(searchValue);
    }

    return '';
  }

  React.useEffect(() => {
    if (isFacebookInAppBrowser) {
      if (sessionStorage.getItem('dealId')) {
        const element = document.getElementById('dealId' + sessionStorage.getItem('dealId'));
        if (element) {
          element.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
          });

          setInListLoadShow(true);
          setTimeout(() => hackFacebook(), 50);

          sessionStorage.removeItem("dealId");
          sessionStorage.removeItem('selected');
          sessionStorage.removeItem('sort');
          sessionStorage.removeItem('search');
        }
        const bar = document.getElementById('bar-search-field');
        if (bar)
          bar.value = search;
        setInitialLoadShow(false);
      }
    }
  }, [deals])

  let touchstartY = 0;
let touchStart = false;

  React.useEffect(() => {
    document.title = 'DEALS EMPIRE';
    const CATEGORIES_ENDPOINT = process.env.REACT_APP_API_URL + "/Categories";

    function getCategories() {
      var url = queryString.stringifyUrl({
        url: CATEGORIES_ENDPOINT
      });

      axios.get(url)
        .then(res => {
          setCategories(res.data);
          setDeals([]);
          setHasMore(true);
          if (!sessionStorage.getItem('selected')) {
            setSelected(res.data.map(x => x.id));
          }

        })
    }

    getCategories();

    document.querySelectorAll('[type=search]').forEach((element) => {
      hideMobileKeyboardOnReturn(element);
    });

    let bar = document.getElementById('bar-search-field');
    if (bar)
      bar.value = search;

      setTimeout(() => {
        setVisibleState(false);
      }, 30000);


      //Refresh content on pulldown   
      document.addEventListener('touchstart', e => {
        if (window.location.hash == 0)
          touchstartY = e.touches[0].clientY;
      });
      document.addEventListener('touchmove', e => {
        if (window.location.hash == 0){
          const touchY = e.touches[0].clientY;
          const touchDiff = touchY - touchstartY;
          if (touchDiff > 0 && window.scrollY === 0) {
          
            let element = document.getElementById('refreshIconTop');
            var top = ((touchDiff/2)-50) > 75 ? 75 : ((touchDiff/2)-50) ;
            element.style.top = top + "px";
            element.classList.add("visible");
            touchStart = true;
            e.preventDefault();
          }
        }
      });
      document.addEventListener('touchend', e => {
        if (window.location.hash == 0){
          if (touchStart) {
            touchStart = false;
            let element = document.getElementById('refreshIconTop');
            element.classList.remove("visible");
            element.style.top = "-50px";
            refreshPage();
          }
        }
      });
    
  }, []);

  function hackFacebook() {
    if (window.scrollY > 125) {
      if (!sessionStorage.getItem('scrollDirectionUp')) {
        document.getElementById('scroll-to-top').style.bottom = "67px";
      }
      else
        document.getElementById('scroll-to-top').style.bottom = "23px";
    }
    sessionStorage.setItem('scrollDirectionUp', true);
  }

  const launchSearch = (e) => {
    isRefScroller.current.pageLoaded = 0;
    setHasMore(true);
    setSearch(e.target.value);
    setDeals([]);
    gaEventTracker('search', e.target.value);
    setTimer(false);
  }

  const handleChange = (event) => {
    setNoResult(false);
    setDeals([]);
    setSort(event.target.value);
    setHasMore(true);
    gaEventTracker('order', '',event.target.value);
    setTimer(false);
  };

  const hideMobileKeyboardOnReturn = (element) => {
    element.addEventListener('keyup', (keyboardEvent) => {
      const key = keyboardEvent.code || keyboardEvent.keyCode;
      if (key === 'Enter' || key === 13) {
        element.blur();
      }
    });
  };

  const deboucedOnChange = debounce(launchSearch, 500);
  


  return (
    <div className="App">

      <Header />
      
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" id="refreshIconTop">
        <RefreshIcon />
      </IconButton>
      <Grid container spacing={2} sx={{ width: '100%', padding: '0 10px 5px 10px', margin: 'auto' }} >
        <Grid xs={12}>
          <div id="bar-search" >
            <TextField
              id="bar-search-field"
              type='search'
              onChange={deboucedOnChange}
              placeholder={t("Search_Default")}
              style={{ width: '100%', backgroundColor: 'white', borderRadius: '4px' }}
              InputProps={{
                endAdornment: <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>,
              }}

            />
          </div>
        </Grid>
        <Grid xs={6}>
          <Select
            id="select-sort"
            style={{ width: '100%', backgroundColor: 'white' }}
            value={sort}
            open={openSort}
            onOpen={() => { setOpenSort(true) }}
            onClose={() => { setOpenSort(false) }}
            onChange={handleChange}
            MenuProps={MenuProps}
          >
            <MenuItem value={3}>{t("Filter_Order_New")}</MenuItem>
            <MenuItem value={1}>{t("Filter_Order_Best")}</MenuItem>
            {/*  <MenuItem value={2} >Popular</MenuItem>*/}
            <MenuItem value={4}>{t("Filter_Order_Discount")}</MenuItem>

          </Select>
        </Grid>

        <Grid xs={6}>
          <FormControl id="multiple-checkbox-form">

            <Select
              labelId="multiple-checkbox-label"
              id="checkboxes-categories"
              multiple
              autoFocus
              value={selected}
              open={openCategorie}
              onOpen={() => { setOpenCategorie(true) }}
              onClose={() => { setOpenCategorie(false) }}
              displayEmpty
              onChange={(event) => {
                setNoResult(false);
                const value = event.target.value;
                setDeals([]);
                setHasMore(true);
                var selectedValue = [];
                if (value[value.length - 1] === "all") {
                  selectedValue = selected.length === categories.length ? [] : categories.map(x => x.id);
                }
                else {
                  selectedValue = (typeof value === 'string' ? value.split(',') : value);
                }
                setSelected(selectedValue);
                gaEventTracker('categories', selected.toString());
                setTimer(false);
              }}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                return <span>{t("Filter_Category_Title")}</span>;
              }
              }
              MenuProps={MenuProps}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem
                value="all"
                className="selectAllCheckbox"
              >
                <ListItemIcon >
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={
                      selected.length > 0 && selected.length < categories.length
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  className='selectAllButton'
                  primary={isAllSelected ? t("Filter_Category_Unselect_All") : t("Filter_Category_Select_All")}
                />
              </MenuItem>

              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id} muiSkipListHighlight>
                  <Checkbox checked={selected.indexOf(category.id) > -1} />
                  <ListItemText className='selectCategories' primary={i18n.language === "fr" ? category.nameFr : category.nameEn} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </Grid>
      </Grid>
      {initialLoadShow &&
        <Grid id="InitialLoad">
          <RotatingLines
            visible={true}
            height="75"
            width="75"
            strokeColor="#a6966d"
            strokeWidth="4"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </Grid>}
      <DealList deals={deals} key={sort + "|" + search + "|" + selected} isLoading={isLoading} sortValue={sort} searchValue={search} selectedValue={selected || []} getDeals={getDeals} hasMore={hasMore} inListLoadShow={inListLoadShow} sx={{ visible: !inListLoadShow }} isRefScroller={isRefScroller} />

      <ScrollToTopButton />
      <div className="feelLuck">
        {noResult && search.length === 0 &&
          <h1>{t("Filter_Category_Result_End_Title")}</h1>
        }
        {noResult && search.length > 0 &&
          <React.Fragment>
            <h1>{t("Search_Result_End_Title")}</h1>
            <span className='feelLuckyDescription'>{t("Search_Result_End_Subtext", { "0": search })}</span><br /><br />
            <Button
              className='button-color1'
              onClick={(e) => {
                if (isFacebookInAppBrowser) {
                  let dealId = 100;
                  if (deals.length > 1)
                    dealId = deals[deals.length - 1];
                  sessionStorage.setItem('dealId', dealId);
                  sessionStorage.setItem('selected', JSON.stringify(selected));
                  sessionStorage.setItem('sort', sort);
                  sessionStorage.setItem('search', search);
                }

                const url = 'https://www.amazon.ca/s?k=' + search + '&rh=p_85%3A5690392011%2Cp_n_deal_type%3A23565508011&dc&qid=1699653303&rnid=23565505011&tag=dealsempireca-20';
                gaEventTracker('feelLucky', search);
                window.open(url, '_blank');
                return false;
              }
            }
            >{t("Search_Result_End_Button")}</Button>
            <br />
          </React.Fragment>
        }
      </div>
      {(!isAppInstalled && !isFacebookInAppBrowser && (isAndroid || isIOS) && (isChrome || isSafari || isFirefox) && isVisible) && 
        <Slide direction="up" in={isVisible} >
          <div id="promptToInstall">
            <Grid container spacing={2} sx={{ width: '100%', margin: 'auto' }} >
              <Grid xs={7} className='verticalAlignCenter'><span>{t("Install_Title")}</span></Grid>
              <Grid xs={5} className='verticalAlignCenter'><button className='button-color1' onClick={promptToInstall}>{t("Install_Yes")}</button></Grid>
            </Grid>
          </div>
        </Slide>   
      }
    </div>
  );
}

export default App;
